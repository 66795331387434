<template>
  <div ref="rootElement">
    <svg
      :width="width"
      :height="height"
      class="fairy-lights"
      :class="{ 'is-scrolling': isScrolling }">
      <!-- Define gradients for the bulbs -->
      <defs>
        <!-- Gradient for the bulb light -->
        <radialGradient
          id="lightGradient"
          cx="50%"
          cy="50%"
          r="50%">
          <stop
            offset="20%"
            stop-color="#fff"
            stop-opacity="1" />
          <stop
            offset="70%"
            stop-color="#ffcc27"
            stop-opacity="0.5" />
          <stop
            offset="80%"
            stop-color="#ffd44b"
            stop-opacity="0.25" />
          <stop
            offset="100%"
            stop-color="#ffd44b"
            stop-opacity="0.1" />
        </radialGradient>

        <!-- Gradient for the glow effect -->
        <radialGradient
          id="glowGradient"
          cx="50%"
          cy="50%"
          r="50%">
          <stop
            offset="0%"
            stop-color="rgb(255, 197, 0)"
            stop-opacity="1" />
          <stop
            offset="100%"
            stop-color="rgb(255, 197, 0)"
            stop-opacity="0" />
        </radialGradient>
      </defs>

      <!-- Main string -->
      <path
        :d="pathData"
        class="string" />

      <!-- Lights with spurs -->
      <g
        v-for="(light, index) in lights"
        :key="index">

        <!-- Spur line -->
        <line
          :x1="light.x0"
          :y1="light.y0"
          :x2="light.x"
          :y2="light.y"
          class="spur" />

        <!-- Glow effect -->
        <circle
          :cx="light.x"
          :cy="light.y"
          :r="glowRadius"
          class="bulb-glow"
          :style="{ animationDelay: `-${light.animationDelay}s` }" />

        <!-- Outer bulb (glass) -->
        <circle
          :cx="light.x"
          :cy="light.y"
          :r="lightRadius"
          class="bulb-glass" />
        <!-- Inner bulb (light) -->
        <circle
          :cx="light.x"
          :cy="light.y"
          :r="lightRadius"
          class="bulb-light"
          :style="{ animationDelay: `-${light.animationDelay}s` }" />
      </g>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useMediaQuery } from "@vueuse/core";

const props = withDefaults( defineProps<{
  lightRadius?: number;
  lightSpacing?: number;
  mobileLightRadius?: number;
  mobileLightSpacing?: number;
  left?: number;
  right?: number;
  mobileLeft?: number;
  mobileRight?: number;
  lightStartOffset?: number;
  lightEndOffset?: number;
  curveControlX1?: number;
  curveControlY1?: number;
  curveControlX2?: number;
  curveControlY2?: number;
  stringColor?: string;
}>(), {
  left: 0,
  right: 130,
  mobileLeft: 20,
  mobileRight: 100,
  lightRadius: 9,
  mobileLightRadius: 7,
  lightSpacing: 80,
  mobileLightSpacing: 50,
  lightStartOffset: 0.05,
  lightEndOffset: 0.95,
  curveControlX1: 0.25,
  curveControlY1: 150,
  curveControlX2: 0.75,
  curveControlY2: 150,
  stringColor: "#333349",
} );

interface Light {
  x0: number;
  y0: number;
  x: number;
  y: number;
  animationDelay: number;
}

// Reactive data
const rootElement = ref<HTMLElement | null>( null );
const width = ref( 0 );
const height = ref( 200 );
const lights = ref<Light[]>( [] );
const pathData = ref( "" );

const isScrolling = ref( false );

const mq = useMediaQuery( "(max-width: 768px)" );

const lightRadius = computed( () => {
  return ( mq.value ? props.mobileLightRadius : props.lightRadius ) || 10;
} );

const glowRadius = computed( () => {
  return lightRadius.value * 2.5;
} );

const lightSpacing = computed( () => {
  return ( mq.value ? props.mobileLightSpacing : props.lightSpacing ) || 100;
} );

watch( props, () => {
  calculateLights();
} );

const updateDimensions = () => {
  if ( rootElement.value ) {
    const newWidth = rootElement.value.clientWidth;
    if ( width.value !== newWidth ) {
      width.value = newWidth;
      calculateLights();
    }
  }
};

// Scroll event handling
let scrollTimeout: ReturnType<typeof setTimeout>;
const onScroll = () => {
  clearTimeout( scrollTimeout );
  isScrolling.value = true;
  scrollTimeout = setTimeout( () => {
    isScrolling.value = false;
  }, 100 );
};

// Function to get a point along a cubic Bezier curve
const getCubicBezierPoint = (
  x1: number, y1: number,
  cx1: number, cy1: number,
  cx2: number, cy2: number,
  x2: number, y2: number,
  t: number,
) => {
  const x = Math.pow( 1 - t, 3 ) * x1
    + 3 * Math.pow( 1 - t, 2 ) * t * cx1
    + 3 * ( 1 - t ) * t * t * cx2
    + Math.pow( t, 3 ) * x2;
  const y = Math.pow( 1 - t, 3 ) * y1
    + 3 * Math.pow( 1 - t, 2 ) * t * cy1
    + 3 * ( 1 - t ) * t * t * cy2
    + Math.pow( t, 3 ) * y2;
  return { x, y };
};

// Calculate positions of lights and path
const calculateLights = () => {
  const tStart = props.lightStartOffset ?? 0;
  const tEnd = props.lightEndOffset ?? 1;
  const numberOfLights = Math.floor(
    ( tEnd - tStart ) * width.value / lightSpacing.value,
  );
  lights.value = [];

  // Curve parameters
  const startX = 0;
  const startY = mq.value ? ( props.mobileLeft ?? props.left ) : ( props.left ?? 0 );
  const endX = width.value;
  const endY = mq.value ? ( props.mobileRight ?? props.right ) : ( props.right ?? 120 );
  const controlX1 = width.value * ( props.curveControlX1 ?? 0.25 );
  const controlY1 = props.curveControlY1 ?? 150;
  const controlX2 = width.value * ( props.curveControlX2 ?? 0.75 );
  const controlY2 = props.curveControlY2 ?? 150;

  // Generate the path data for a cubic Bezier curve
  pathData.value = `M${startX},${startY} C${controlX1},${controlY1} ${controlX2},${controlY2} ${endX},${endY}`;

  // Place lights along the curve
  for ( let i = 0; i <= numberOfLights; i++ ) {
    const t = tStart + ( ( tEnd - tStart ) * i ) / numberOfLights;
    const { x: x0, y: y0 } = getCubicBezierPoint(
      startX,
      startY,
      controlX1,
      controlY1,
      controlX2,
      controlY2,
      endX,
      endY,
      t,
    );

    // Define spur length with slight randomness
    const spurLength = 15 + ( Math.random() - 0.2 ) * 1;

    // Light position below the curve point
    const x = x0 + ( Math.random() - 0.5 ) * 10;
    const y = y0 + spurLength + ( Math.random() - 0.5 ) * 1;

    lights.value.push( {
      x0,
      y0,
      x,
      y,
      animationDelay: Math.random() * 20,
    } );
  }
};

let resizeObserver: ResizeObserver;

onMounted( () => {
  updateDimensions();

  if ( rootElement.value ) {
    resizeObserver = new ResizeObserver( () => {
      updateDimensions();
    } );
    resizeObserver.observe( rootElement.value );
  }

  window.addEventListener( "scroll", onScroll, { passive: true } );
} );

onBeforeUnmount( () => {
  if ( resizeObserver ) {
    resizeObserver.disconnect();
  }
  window.removeEventListener( "scroll", onScroll );
} );
</script>

<style scoped>
.fairy-lights {
  display: block;
  overflow: visible;
  pointer-events: none;
}

/* Style for the main string */
.string {
  stroke: v-bind(stringColor);
  stroke-width: 3;
  fill: none;
}

/* Style for the spurs */
.spur {
  stroke: v-bind(stringColor);
  stroke-width: 6;
}

/* Style for the bulb glass */
.bulb-glass {
  fill: rgba(33, 33, 33, 0.1);
  stroke: #ccc2;
  stroke-width: 1;
}

/* Style for the bulb light */
.bulb-light {
  fill: url(#lightGradient);
}

/* Style for the bulb glow */
.bulb-glow {
  fill: url(#glowGradient);
  animation: glow-animation 8s ease-in-out infinite alternate;
  transform-origin: center;
  transform-box: fill-box;
}

.is-scrolling .bulb-glow {
  animation-play-state: paused;
}

/* Glow animation */
@keyframes glow-animation {
  0%,
  100% {
    transform: scale(1.6);
    opacity: 0.125;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.2;
  }
}
</style>
